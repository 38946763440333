.header {
  color: #0922fd;
  text-align: center;
  font-size: 62px;
  line-height: 100px;
  margin: 35px 0px 35px;
}
.navbar-brand.title {
  font-family: "poppins";
  color: black;
  font-weight: bolder;
}
.form-control.search-term {
  text-align: center;
  margin: 0px 0px 35px;
}
.form-control.not-found {
  text-align: center;
  margin: 14px 5px 0px 5px;
}
.content img {
  width: 100px;
}
.line:after {
  content: "\A";
  white-space: pre;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.card {
  background-color: #f0f0f0;
  border-color: white;
  width: 80%;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  padding: 0;
}
@media screen and (max-width: 500px) {
  .navbar-brand {
    font-size: 11px;
  }
  .header {
    font-size: 25px;
  }
  #content img {
    width: 55px;
  }
  #details-button.btn-primary {
    width: 100px;
    font-size: 11px;
  }
}

.btn-primary {
  background-color: #0922fd;
  margin: 13px;
}

.btn-secondary {
  background-color: #0922fd;
  position: absolute;
  top: 0;
  right: 0;
}

.btn-secondary:hover {
  background-color: #0922fd;
}

.details-large {
  width: 1100px;
  height: 300px;
  margin: 0 auto;
  padding: 15px;
  border-radius: 9px;
  margin-bottom: 25px;
}

.details-small {
  width: 1100px;
  height: 50px;
  margin: 0 auto;
  padding: 15px;
  border-radius: 9px;
  margin-bottom: 25px;
}

.centered {
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -350px;
}

.alignright {
  float: right;
  padding: 35px;
}

.icon {
  height: 25px;
  width: 25px;
  margin-right: 0px;
  align-items: center;
}
.buttonText {
  color: #4285f4;
  font-weight: bolder;
}
